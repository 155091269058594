<template>
  <div class="contact">
    <div class="aboutOurCompany">
      <div class="title"><h3>الأخبار</h3></div>

      <v-divider></v-divider>
      <v-container>
        <v-row>
          <h4 class="blue--text">
            شركه مياه الشرب والصرف الصحي بأسيوط والوادي الجديد
          </h4>
          <span class="ml-2 mr-2">/</span
          ><span class="gray--text"> أخبار </span>
        </v-row>
        <v-row class="mt-10" v-if="items">
          <v-col class="subClass" cols="12" sm="12" xs="12" lg="9">
            <h3 v-if="items.title">
              {{ items.title }}
            </h3>
            <hr class="ma-3 fade-in-right center" justify="center" />
            <span class="ql-editor" v-if="items.description" v-html="items.description"> </span>
            <v-container class="pa-4 text-center">
              <v-row class="fill-height" align="center" justify="center">
                <v-container class="pa-4 text-center">
                  <v-row class="fill-height" align="center" justify="center">
                    <v-col
                      cols="12"
                      md="4"
                      v-for="img in items.news_images"
                      :key="img"
                    >
                      <expandable-image
                        :src="
                          'https://backend.ascww.org/api/news/image/' + img.path
                        "
                      ></expandable-image>
                      <!-- <expandable-image
                        :src="'https://backend.ascww.org/api/image/' + img.path"
                      >
                      </expandable-image>-->
                    </v-col>
                  </v-row>
                </v-container>
              </v-row>
            </v-container>
            <!--  <p v-if="item.details2">{{item.details2}}</p>
            <p v-if="item.details3">{{item.details3}}</p> -->
          </v-col>
          <v-col cols="12" sm="12" xs="12" lg="3">
            <LinkX></LinkX>
          </v-col>
        </v-row>
        <v-row v-else>
          <h3>
            عذرا لا يوجد خبر بهذا الرابط
          </h3>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>
import LinkX from "../components/link.vue";
export default {
  name: "Link",
  created() {
    this.$store.dispatch("findSelectedItem");
  },
  components: {
    LinkX,
  },

  data() {
    return {
      model: null,
      userId: this.$route.params.userId,
    };
  },

  methods: {
    checkID() {
      this.userId = this.$route.params.userId;
    },
    /*  callFun(text) {
      var mySubString = text.substring(
        text.indexOf("https") - 1,
        text.lastIndexOf("//")
      );
      return mySubString;
    },
    change(text) {
      return text.replace(this.callFun(text), " ");
    },*/
  },
  computed: {
    items() {
      //  this.$store.dispatch('findSelectedItem',this.userId)
      return this.$store.state.newsArrayStore.find(
        (item) => item.slug == this.userId
      );
    },
    TotalText() {
      var xx = this.items.description;
      const words = xx.split("*");
      return words;
    },
  },

  watch: {
    $route: "checkID",
  },
};
</script>
<style>
h2 {
  width: 600px !important;
}
.subtextnews {
  font-size: 15px;
}
</style>
